#dilView {
  & > * {
    margin-bottom: 2rem;
  }
}

.dil-status {
  color: rgba(4, 168, 163, 1);
  font-size: 1.8rem;
  font-weight: bold;
}

.dil-elab-properties {
  border-bottom: none;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 0;
}

.dil-elab-properties-values {
  color: rgba(4, 168, 163, 1);
  font-size: 3rem;
  font-weight: bold;
}

// Dominance Styles

.dominance-wrapper {
  color: #ffffff;
  text-align: left;
  border-radius: 10px;
  &_header {
    font-weight: bold;
  }

  &_para {
    font-size: 1.5rem;
    font-weight: bold;
    color: rgba(4, 168, 163, 1);
  }
  .small {
    font-size: 1rem;
  }

  // &:hover {
  //   background-color: #223441;
  //   cursor: pointer;
  // }
}

.alert-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #223441;
  border: none;
  color: white;

  p {
    font-size: 2rem;
    font-weight: bold;
  }

  button {
    font-weight: bold;
  }

  p.button-text {
    font-size: 1rem;
  }
}

//agGrid Custom Styles
.ag-theme-material {
  --ag-header-background-color: rgba(4, 168, 163, 0.5);
  --ag-header-cell-hover-background-color: rgba(37, 54, 66, 1);
  --ag-header-foreground-color: white;
  --ag-font-family: 'Barlow';
  --ag-foreground-color: white;
  --ag-background-color: #15222b;
  --ag-row-border-width: 1px;
  --ag-row-border-color: rgba(150, 150, 200, 0.3);
  --ag-odd-row-background-color: rgba(37, 54, 66, 1);
  --ag-row-hover-color: rgb(69, 89, 104);
}

.ag-paging-panel {
  background-color: rgba(4, 168, 163, 0.5);
  color: white;
}

.custom-grid-class {
  font-weight: bold;
  color: white;
}

.ag-header {
  border: 0 !important;
}

.ag-header-cell {
  font-weight: bold;
  border-bottom: 1px solid #94bbb3;
}

.ag-header-cell-label {
  justify-content: center;
}
.ag-header-cell-text {
  font-size: 1rem;
}

// .ag-theme-custom-text-right .ag-header-cell-label {
//   text-align: center;
//   // padding-right: 10px; // just to make sure that text doesn't go hit itself to next column border
//   display: inline-grid;
// }

// .ag-center-cols-container > span:last-child {
//   // min-width: 248px;
// }

.pagination-text {
  color: #ffffff;
}

tbody tr:nth-of-type(odd) {
  background-color: #223441;
}

tbody tr {
  background-color: #31495a;
}

.column-visible label {
  color: white;
}

tbody:before {
  content: '@';
  display: block;
  line-height: 0px;
  text-indent: -99999px;
}

// Main Grid
.columnDropdownWrapper,
.columnDropdownElementLabel {
  color: #ffffff;
  font-weight: bold;
}

#main-table {
  border-collapse: separate;
  border-spacing: 5px 0;
  thead {
    color: #ffffff;
    background-color: #223441;
    th {
      width: auto;
      min-width: 150px;
      height: auto;
      white-space: nowrap;
      margin: 3rem;
      border-bottom: none;

      button {
        color: #ffffff;
        background: none;
        border: none;
      }
    }
  }
  tbody {
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: bold;
    td {
      min-width: auto;
      text-align: left;
      white-space: nowrap;
      padding: 0.9rem 7rem 0.9rem 1rem;
      border: none;
    }
    // tr:nth-child(even) {
    //   color: #cfbb16f0;
    // }
  }
}

// Internal Grid
.internalGridShowButton {
  padding: 0rem 0.5rem;
}

.internalGridWrapper {
  background-color: #15222b;
}

.internalGridBody {
  color: white;
  font-size: 0.9rem;
  font-weight: bold;

  td {
    border: none;
  }
}

.internalGridHead {
  color: white;
  background-color: #223441;

  th {
    border: none;
  }
}

.internalGridRow {
  color: #ffffff;
}

// .logo-container {
//   position: relative;
//   .logo {
//     margin: 2rem 0;
//     max-width: 50%;
//   }

//   &::after {
//     content: '';
//     position: absolute;
//     width: 80%;
//     height: 2px;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     margin: auto;
//     background-color: rgba(4, 168, 163, 0.5);
//   }
// }

// .sidebar {
//   background-color: white;
//   min-width: 250px;
//   position: sticky;
//   top: 0;
// }

// .nav {
//   margin-top: 3rem;
//   ul {
//     list-style: none;
//     text-decoration: none;
//     text-align: left;
//     margin: auto;
//     padding: 0px;

//     li {
//       margin: 1.5rem 0;

//       a {
//         color: #15222b;
//         font-size: 1.2rem;
//         font-weight: bold;
//         text-decoration: none;
//         padding: 0.5rem 1rem;
//       }
//     }
//   }
// }

// .active {
//   border-radius: 7px;
//   background-color: rgba(4, 168, 163, 1);
//   color: white !important;
// }

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  height: 100%;
  padding: 40px 10px 30px 10px;
  border-right: 1px solid #2f3336;
  background-color: white;
  z-index: 99;
  nav {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  a {
    text-align: left;
    color: #15222b;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
  }

  button {
    height: 60px;
    background: transparent;
    border: 0;
    border-radius: 7px;
    padding: 0;
    font-family: inherit;
    color: inherit;
    cursor: pointer;
    text-align: left;

    span {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      height: 48px;
      padding: 0 16px 0 12px;
      border-radius: 7px;
      line-height: 1;
      font-size: 25px;
    }
    i {
      position: relative;
      font-size: 28px;
      transition: 0.2s;
    }

    &:hover {
      background-color: rgba(4, 168, 163, 0.1);
    }
  }
}

.sidebar > nav button:last-child {
  margin-top: auto;
}

button.closeBtn span {
  width: 100%;
}

button.closeBtn span > span {
  display: grid;
  gap: 4px;
  width: 100%;
}

button.closeBtn span > i {
  font-size: 20px;
  margin-left: auto;
}

// .sidebarExpandButton {
//   height: 60px;
//   background: transparent;
//   border: 0;
//   border-radius: 7px;
//   padding: 0;
//   font-family: inherit;
//   color: inherit;
//   cursor: pointer;
//   text-align: left;

//   span {
//     display: inline-flex;
//     align-items: center;
//     gap: 12px;
//     height: 48px;
//     padding: 0 16px 0 12px;
//     border-radius: 7px;
//     line-height: 1;
//     font-size: 25px;
//   }
//   i {
//     position: relative;
//     font-size: 28px;
//     transition: 0.2s;
//   }

//   &:hover {
//     background-color: rgba(4, 168, 163, 0.1);
//   }
// }

.sidebar-header {
  width: 100%;
  margin-bottom: 16px;
}

.logo-container {
  position: relative;
  .logo {
    margin: 2rem 0;
    max-width: 80%;
  }

  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(4, 168, 163, 0.5);
  }
}

.closed {
  width: 72px;
}

.closed button > span {
  width: 50px;
}

.closed button > span > span {
  opacity: 0;
  visibility: hidden;
}

.active {
  border-radius: 7px;
  background-color: rgba(4, 168, 163, 1);
  color: white !important;
}

// #grid-wrapper {
//   width: 100%;
//   height: 100%;
// }

// .projects-list-table {
//   width: 100%;
//   height: 100%;

//   .ag-body-horizontal-scroll {
//     display: none;
//   }

//   .ag-horizontal-right-spacer::-webkit-scrollbar {
//     display: none;
//   }
// }

// #output-wrapper,
// #dominance-wrapper {
//   width: calc(100% - 72px);

//   // @media (width < 992px) {
//   //   width: calc(100% - 72px);
//   // }
// }

.content-box {
  border: 2px solid rgba(4, 168, 163, 1);
  border-radius: 10px;
  min-height: 200px;
}

.form-control {
  border: none;
  background-color: #15222b;
  color: white;
  @media (max-width: 1200px) {
    flex-direction: column;
  }

  & > * {
    margin-right: 0.5rem;
  }
}

.grid-container {
  margin-top: 2rem;

  .btn-container {
    margin: 2rem 0;
  }
}

.dropdown-item:active {
  background-color: rgba(4, 168, 163, 1);
  color: 'white';
}
.dropdown-toggle.show {
  background-color: rgba(4, 168, 163, 1);

  border: 1px solid rgba(4, 168, 163, 1);
}

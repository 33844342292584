.App {
  text-align: center;
  min-height: 100vh;
  font-family: 'Barlow', sans-serif;
  background-color: #15222b;
}

.btn-primary {
  background-color: rgba(4, 168, 163, 1);
  border: 1px solid rgba(4, 168, 163, 1);
  font-weight: bold;

  &:hover {
    background-color: transparent !important;
    border: 1px solid rgba(4, 168, 163, 1);
  }

  &:active,
  &:focus {
    background-color: rgba(4, 168, 163, 1) !important;
    border: 1px solid rgba(4, 168, 163, 1) !important;
  }
}
.btn-success {
  font-weight: bold;
}
.btn-warning {
  font-weight: bold;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
  color: white;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid white;
  // &::after {
  //   content: '';
  //   // position: absolute;
  //   width: 100%;
  //   height: 2px;
  //   left: 0;
  //   bottom: 0;
  //   margin: auto;
  //   background-color: rgba(4, 168, 163, 0.5);
  // }
}
p {
  margin: 0;
}
label {
  color: white;
  text-align: left;
}

#page-title {
  max-width: 90%;
  margin: 2rem 0 1rem 2rem;
  text-transform: uppercase;
}

.error-message {
  min-height: 300px;
  &_header {
    color: orange;
    font-size: 2rem;
    border: none;
  }
}

.global-toast {
  font-family: 'Barlow', sans-serif;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9999;
  min-width: 20rem;
  height: auto !important;
  color: initial !important;
  padding: 0 !important;
  font-size: 1rem !important;
  background-color: white !important;
  border: 2px solid rgba(4, 168, 163, 1);

  /* .toast-header {
    button.close {
     
    }
  } */
}

.custom-form-field {
  background-color: white;
}

.alert{
  color: red;
}

.white-input {
  background-color: white !important;
  color: rgb(4, 168, 163) !important;
}

.label {
  font-weight: bold;
  color: rgb(4, 168, 163)
}

.display-none {
  display: none;
}

.spinner {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


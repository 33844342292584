// Custom Dropdown
.custom-dropdown-wrapper {
  position: relative;
  margin-bottom: 0.4rem;
}
.custom-dropdown-button {
  color: #ffffff;
  background-color: #223441;
  border: none;
  font-size: 1.5rem;
  padding: 0.5rem 1.2rem;

  span {
    font-size: 1.2rem;
  }
}

.expanded-wrapper {
  position: absolute;
  top: 58px;
  background-color: #223441;
  max-height: 400px;
  min-width: 350px;
  width: max-content;
  overflow-y: auto;
  overflow-x: none;
  padding: 1rem 0 1rem 1rem;
}

.expanded-wrapper-children {
  position: relative;
}

.expanded-close-btn {
  position: absolute;
  padding: 0;
  top: 0;
  right: 0;
  z-index: 1;
}
